// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["g6A2sW8l2", "DPGTjaIEi", "Lv1SvWJhg"];

const serializationHash = "framer-irdUn"

const variantClassNames = {DPGTjaIEi: "framer-v-17c7ryi", g6A2sW8l2: "framer-v-lnzkfa", Lv1SvWJhg: "framer-v-14ru29f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Bold: "bold", Duotone: "duotone", Fill: "fill", Light: "light", Regular: "regular", Thin: "thin"}

const humanReadableVariantMap = {Default: "g6A2sW8l2", Muted: "DPGTjaIEi", Primary: "Lv1SvWJhg"}

const getProps = ({height, icon, iconWeight, id, width, ...props}) => { return {...props, Fl52gRK5S: icon ?? props.Fl52gRK5S ?? "Check", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "g6A2sW8l2", W7ZAMMyrB: humanReadableEnumMap[iconWeight] ?? iconWeight ?? props.W7ZAMMyrB ?? "light"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string;iconWeight?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Fl52gRK5S, W7ZAMMyrB, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "g6A2sW8l2", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-lnzkfa", className, classNames)} data-border data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"g6A2sW8l2"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgba(218, 197, 167, 0.15))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, ...style}} variants={{DPGTjaIEi: {"--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgb(51, 51, 48))", backgroundColor: "var(--token-cd2934a7-4e35-4347-a32c-9650fca4db23, rgb(242, 242, 242))"}, Lv1SvWJhg: {"--border-bottom-width": "0px", "--border-left-width": "0px", "--border-right-width": "0px", "--border-top-width": "0px", backgroundColor: "var(--token-dca875b7-f855-43c1-bf73-97596e452266, rgb(203, 251, 69))"}}} {...addPropertyOverrides({DPGTjaIEi: {"data-framer-name": "Muted"}, Lv1SvWJhg: {"data-framer-name": "Primary"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-oxqgcq-container"} layoutDependency={layoutDependency} layoutId={"B1UfEq1_o-container"}><Phosphor color={"var(--token-efbc6c56-80fe-4475-9b37-9684d6e92632, rgb(21, 21, 21))"} height={"100%"} iconSearch={"House"} iconSelection={Fl52gRK5S} id={"B1UfEq1_o"} layoutId={"B1UfEq1_o"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={W7ZAMMyrB} width={"100%"} {...addPropertyOverrides({Lv1SvWJhg: {color: "var(--token-f82457c5-8438-48a1-bcce-dde3448a0d3a, rgb(255, 255, 255))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-irdUn.framer-yh0g, .framer-irdUn .framer-yh0g { display: block; }", ".framer-irdUn.framer-lnzkfa { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 32px; height: 26px; justify-content: center; overflow: visible; padding: 8px 12px 8px 12px; position: relative; width: 26px; }", ".framer-irdUn .framer-oxqgcq-container { flex: none; height: 14px; position: relative; width: 14px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-irdUn.framer-lnzkfa { gap: 0px; } .framer-irdUn.framer-lnzkfa > * { margin: 0px; margin-bottom: calc(32px / 2); margin-top: calc(32px / 2); } .framer-irdUn.framer-lnzkfa > :first-child { margin-top: 0px; } .framer-irdUn.framer-lnzkfa > :last-child { margin-bottom: 0px; } }", ".framer-irdUn[data-border=\"true\"]::after, .framer-irdUn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 26
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"DPGTjaIEi":{"layout":["fixed","fixed"]},"Lv1SvWJhg":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Fl52gRK5S":"icon","W7ZAMMyrB":"iconWeight"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfrSARImZ6: React.ComponentType<Props> = withCSS(Component, css, "framer-irdUn") as typeof Component;
export default FramerfrSARImZ6;

FramerfrSARImZ6.displayName = "Badge (Small)";

FramerfrSARImZ6.defaultProps = {height: 26, width: 26};

addPropertyControls(FramerfrSARImZ6, {variant: {options: ["g6A2sW8l2", "DPGTjaIEi", "Lv1SvWJhg"], optionTitles: ["Default", "Muted", "Primary"], title: "Variant", type: ControlType.Enum}, Fl52gRK5S: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Check", description: undefined, hidden: undefined, title: "Icon"}, W7ZAMMyrB: PhosphorControls?.["weight"] && {...PhosphorControls["weight"], defaultValue: "light", description: undefined, hidden: undefined, title: "Icon Weight"}} as any)

addFonts(FramerfrSARImZ6, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})